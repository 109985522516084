import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

export const query = graphql`
  fragment heroImage on File {
    childImageSharp {
      fluid(maxWidth: 3000, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`

const Hero = ({ image, children }) => (
  <div className="relative bg-black">
    <Img critical className="h-screen w-100 opacity-70" fluid={image} />
    <div className="absolute pin flex flex-col items-center justify-center text-white px-4">{children}</div>
  </div>
)
export default Hero
